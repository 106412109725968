.biochar-project {
    border: 1px solid black;
    color: #000;
    display: inline-block;
    border-radius: 5px;
    margin: 5px 15px 5px 0;
    cursor: pointer;
    padding: 10px;
    width: 250px;
    max-width: 250px;

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    }

    &-home:hover {
        background: linear-gradient(to bottom right, #535988, #313551);
        color: #fff;
    }
}