@mixin wir-sind {
    color: var(--primary-700, #212746);

    /* Display md/Regular */
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 44px;
    /* 122.222% */
    letter-spacing: -0.72px;
}

@mixin wird-sind-heading {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}

@mixin wird-sind-content {
    color: var(--primary-700, #212746);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

@mixin wir-sind-icon {
    color: var(--primary-700, #212746);
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
}

// section padding

@mixin desktop-padding-leftright80 {
    padding-left: 80px;
    padding-right: 80px;
}

@mixin mobile-padding-leftright16 {
    padding-left: 16px;
    padding-right: 16px;
}

// hero

@mixin hero-heading-text {
    color: var(--base-white, #FFF);
    font-family: Cairo;
    font-size: 60px;
    font-style: normal;
    font-weight: 500;
    line-height: 72px;
    letter-spacing: -1.2px;
}


@mixin hero-heading-support-text {
    color: var(--base-white, #FFF);
    font-family: Cairo;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 44px;
    letter-spacing: -0.72px;
}

// hero-mobile

@mixin hero-heading-text-mobile {
    color: var(--base-white, #FFF);
    /* Display lg/Semibold */
    font-family: Cairo;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
    /* 125% */
    letter-spacing: -0.96px;
}

@mixin support-text-mobile {
    color: var(--base-white, #FFF);
    /* Text lg/Regular */
    font-family: Cairo;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 155.556% */
}

@mixin content-mobile-padding {
    @media screen and (max-width: $sm) {
        padding-left: 0;
    }
}