@import '../abstracts/variables.scss';

.bg {
    background: $primary-white;


    &__heading {
        background: linear-gradient(90deg, #DA9DE3, #c8b2cd);
        -webkit-background-clip: text;
        background-clip: text; // Add the standard property
        -webkit-text-fill-color: transparent;
    }
}

.MuiTabs-flexContainer {
    align-items: center;
    width: 100%;
    justify-content: center;
}

html,
body,
#root,
#root>div {
    height: 100%;
    letter-spacing: 0.25px;
}

.MuiTabs-flexContainer {
    justify-content: space-evenly;
}

// body {
//     background-color: #ffffff;
//     background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23212746' fill-opacity='0.27' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
// }