@import '../../abstracts/variables.scss';

#team {
    background: linear-gradient(to bottom, $base-color, #3e4877);

    &__heading {
        background: linear-gradient(90deg, #DA9DE3, #d5e0cd);
        -webkit-background-clip: text;
        background-clip: text; // Add the standard property
        -webkit-text-fill-color: transparent;
    }

    &__profiles-container {
        display: flex;
        justify-content: left;
        align-items: center;
        flex-wrap: wrap;
        // padding-left: 1rem;
        // padding-right: 1rem;

        &__card {
            opacity: 0;
            transform: scale(0.9);
            transition: opacity 0.65s ease-out, transform 0.65s ease-out;
            transform: translateY(-10);

            &.visible {
                opacity: 1;
                transform: scale(1);
                transform: translateY(0);
            }
        }

        &--advisors {
            display: flex;
            justify-content: left;
            align-items: center;
            flex-wrap: wrap;
            // padding-left: 1rem;
            // padding-right: 1rem;
        }
    }


    &.MuiGrid-root {
        padding: 30px 100px 50px 100px;
    }

    @media only screen and (max-width: 600px) {
        &.MuiGrid-root {
            padding: 30px 10px 30px 10px;
        }

        &__profiles-container {
            justify-content: center;

            &--advisors {
                justify-content: center;
            }
        }
    }

}



// .footer {
//     color: white;
//     font-family: 'Cairo';
//     position: absolute;
//     bottom: 0;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     margin: 0 auto;
//     opacity: 30%;
//     font-size: 14px;
// }

#team-image {
    -webkit-filter: grayscale(100%);
    /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}

#scroll-to-top-btn {
    background-color: #DA9DE3;
    position: absolute;
    right: 2rem;
    bottom: 2rem;
    display: inline-block;
    border-radius: 50%;
    height: 65px;
    width: 65px;
    // background-image: ("../../../assets/images/arrow-circle-up.png");
    // background-size: cover;
    // padding: 0;
}