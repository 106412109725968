@import "../../abstracts/variables.scss";

#introduction {

  background: linear-gradient(to bottom, $base-color, #3e4877);

  &__support-text {
    color: $primary-white;
    // display: flex;
    // width: 1280px;
    // padding: 0px 32px;
    align-items: flex-end;
    gap: 32px;

    color: var(--base-white, #fff);
    /* Text xl/Regular */
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }

  // &__support-text--bold {
  //   font-weight: bold;
  // }

  &.MuiGrid-root {
    padding: 80px 100px 80px 100px;
  }

  @media only screen and (max-width: 600px) {
    &.MuiGrid-root {
      padding: 30px 10px 30px 10px;
    }
  }
}