$base-color: #212746;
// $base-color: #fbfbfb;
$primary-theme-color: #DA9DE3;
$text-color: #151924;
$light-text-color: #151924;
$support-text-color: rgba(21, 25, 36, 0.38);
$primary-white: #ffffff;
$secondary-dark: #151924;
// breakpoints
$sm: 600px;
$md: 900px;
$lg: 1200px;
$xl: 1536px;