@import "../../abstracts/mixins.scss";

.contact {
  // width: 100%;
  // justify-content: space-between;
  background: #D3D3D3;


  // &::before {
  //   content: "";
  //   position: absolute;
  //   top: 0px;
  //   right: 0px;
  //   bottom: 0px;
  //   left: 0px;
  //   opacity: 0.93;
  // }
  &__heading {
    background: linear-gradient(90deg, #DA9DE3, #b4e492);
    -webkit-background-clip: text;
    background-clip: text; // Add the standard property
    -webkit-text-fill-color: transparent;
    // font-size: 3em;
  }

  &__content {
    display: flex;
    // width: 100%;

  }

  &__paper {
    // height: 250px;
    // width: 205px;


    // @media only screen and (max-width: 600px) {
    //   &__paper {
    //     width: 100%;
    //   }
    // }

    &--no-bg {
      background-color: #e6fef5;
      padding-top: 0;
      padding-right: 0;
      padding-bottom: 0;
    }

    &__heading {
      @include wir-sind;


      &__support-text {
        margin-top: 20px;
        @include wird-sind-heading;
      }
    }

    &__info {
      padding: 20px;
      font-family: 'cairo';
      // margin-left: 35px;

      &__icon {
        @include wir-sind-icon;
      }

      &__heading {
        @include wird-sind-heading;
      }

      &__content {
        @include wird-sind-content;
      }

      &__sub-text {
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }

      &--contact {
        // background: linear-gradient(to bottom, $base-color, #3e4877);
      }
    }
  }



  &.MuiGrid-root {
    padding: 80px 100px 80px 100px;

  }

  @media only screen and (max-width: 600px) {
    &.MuiGrid-root {
      padding: 30px 10px 30px 10px;
    }
  }
}