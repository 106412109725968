#pricing {

    &__card {
        opacity: 0;
        transform: scale(0.9);
        transition: opacity 0.65s ease-out, transform 0.65s ease-out;
        transform: translateY(-10);

        &.visible {
            opacity: 1;
            transform: scale(1);
            transform: translateY(0);
        }
    }

    &__heading {
        background: linear-gradient(90deg, #DA9DE3, #d5e0cd);
        -webkit-background-clip: text;
        background-clip: text; // Add the standard property
        -webkit-text-fill-color: transparent;
        // font-size: 3em;
    }

    &.MuiGrid-root {
        // padding: 30px 100px 50px 100px;
    }

    // @media only screen and (max-width: 600px) {
    //     &.MuiGrid-root {
    //         padding: 30px 10px 30px 10px;
    //     }
    // }
}