@import "../../abstracts/variables.scss";

#kiln {
  padding: 100px;
  background: #fff;

  // &__picture {
  //     background-color: red;
  // }

  // &__description {
  //     background: "green";
  // }
  &__heading {
    background: linear-gradient(90deg, #DA9DE3, #b4e492);
    -webkit-background-clip: text;
    background-clip: text; // Add the standard property
    -webkit-text-fill-color: transparent;
    // font-size: 3em;
  }

  &__image {
    // height: 592px;
    // width: 710px;
    background-image: url("../../../assets/images/fire.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    // background-attachment: scroll;
    background-position: center;
    // height: 100%;
    object-fit: fill;
  }

  &.MuiGrid-root {
    padding: 80px 100px 80px 100px;
  }

  @media only screen and (max-width: 600px) {
    &.MuiGrid-root {
      padding: 30px 10px 30px 10px;
    }
  }


}