.footer {
    background-color: #f5f6f4;
    z-index: 100;


    &-section-one {
        background-color: #f5f6f4;
        display: flex;
        justify-content: space-between;
        // height: 215px;
        // align-items: center;

    }

    &-section-two {
        // background: linear-gradient(to bottom, #f5f6f4, #aab2d1);
        background-color: #f5f6f4;
        display: flex;
        align-items: center;
        height: 100px;
        width: 100%;
    }

    &-brand {
        opacity: 0.5;
        padding: 0px;
        margin: 0px;
    }

    &-section-one.MuiGrid-root {
        padding: 50px 50px 50px 50px;
    }

    &-section-two.MuiGrid-root {
        // padding: 40px 100px;
    }

    @media only screen and (max-width: 600px) {
        &-section-one.MuiGrid-root.MuiGrid-item {
            // padding: 0px;
            // margin: 500px;
            // padding-left: 0;
            // background-color: orange;
        }

        &-section-two.MuiGrid-root.MuiGrid-item {
            padding: 10px 100px;
        }

        // #smartphone h1 {
        //     display: block;
        // }
    }
}