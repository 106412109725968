@import "../../abstracts/variables.scss";
@import "../../abstracts/mixins.scss";

#hero {
    &.MuiGrid-root {
        @include desktop-padding-leftright80;

        @media screen and (max-width: $sm) {
            @include mobile-padding-leftright16
        }
    }

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    position: relative;

    &::before {
        content: "";
        background-image: url("../../../assets/images/hero-darker.png");
        background-size: cover;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        opacity: 0.93;
        filter: brightness(0.85);
    }

    &__content {
        padding-left: 32px;
        display: inline-block;
        position: absolute;
        bottom: 15%;
        @include content-mobile-padding;

        &__heading {
            @include hero-heading-text;
            background: linear-gradient(90deg, #ffffff, #d1abd8);
            -webkit-background-clip: text;
            background-clip: text; // Add the standard property
            -webkit-text-fill-color: transparent;

            @media screen and (max-width: $sm) {
                @include hero-heading-text-mobile
            }
        }

        &__support-text {
            margin-top: 10px;
            @include hero-heading-support-text;
            background: linear-gradient(90deg, #ffffff, #d9bddd);
            -webkit-background-clip: text;
            background-clip: text; // Add the standard property
            -webkit-text-fill-color: transparent;

            @media screen and (max-width: $sm) {
                @include support-text-mobile
            }
        }
    }
}