@import "../../abstracts/variables.scss";

#partners {
    // padding: 100px;
    background-color: #fff;

    // &__image {
    //     height: 592px;
    //     width: 710px;
    //     background-image: url("../../../assets/images/fire.jpg");
    //     background-size: cover;
    //     background-repeat: no-repeat;
    //     // background-attachment: scroll;
    //     background-position: center;
    //     // height: 100%;
    //     object-fit: fill;
    // }

    &__heading {
        background: linear-gradient(90deg, #DA9DE3, #b4e492);
        -webkit-background-clip: text;
        background-clip: text; // Add the standard property
        -webkit-text-fill-color: transparent;
        // font-size: 3em;
    }

    &.MuiGrid-root {
        padding: 30px 100px 30px 100px;
        height: 425px
    }

    @media only screen and (max-width: 1200px) {
        &.MuiGrid-root {
            padding: 30px 10px 30px 10px;
            height: auto
        }
    }
}

// <!DOCTYPE html>
// <html lang="en">
// <head>
//   <meta charset="UTF-8">
//   <meta name="viewport" content="width=device-width, initial-scale=1.0">
//   <style>
//     body {
//       font-family: Arial, sans-serif;
//       margin: 0;
//       padding: 0;
//       box-sizing: border-box;
//     }

//     .project-container {
//       display: flex;
//       flex-wrap: wrap;
//     }

//     .project {
//       width: 50%;
//       padding: 20px;
//       box-sizing: border-box;
//       display: flex;
//       align-items: center;
//     }

//     .project img {
//       max-width: 100%;
//       height: auto;
//     }

//     @media (max-width: 768px) {
//       .project {
//         width: 100%;
//       }
//     }
//   </style>
//   <title>Project Showcase</title>
// </head>
// <body>

// <div class="project-container">
//   <div class="project">
//     <div class="project-description">
//       <h2>Project 1</h2>
//       <p>This is the description of Project 1. It can be as long as you need it to be.</p>
//     </div>
//     <img src="project1.jpg" alt="Project 1">
//   </div>

//   <div class="project">
//     <div class="project-description">
//       <h2>Project 2</h2>
//       <p>This is the description of Project 2. It can be as long as you need it to be.</p>
//     </div>
//     <img src="project2.jpg" alt="Project 2">
//   </div>

//   <!-- Add more projects as needed -->

// </div>

// </body>
// </html>